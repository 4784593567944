<template>
  <div class="skill">
    <div class="title">Skills & Experiences</div>
    
    <div class="mainbody">
      <div class="bodytext">
        <p>
          In the field of software engineering, I've done multiple personal projects, been consulted for
          RPA tasks for SMEs, collaborated with talented individuals for hackathons 
          and competitions to produce works of innovation for business and consumer use.
        </p>
        <p>
          I also have years of experience in creative works such as photography, digital
          illustration, graphic design. I was a publicity director for a few events around NTU 
          and have been featured as a photographer for a few small companies
        </p>
        <p>
          More details can be found at my <em @click="link1">LinkedIn</em> profile or <em @click="showe">contact</em> me <em style="cursor:default" v-show="showemail">richieang002@gmail.com</em> for my resume.
        </p>
      </div>
      <div class="pchart">
        <div class="head2">Software Engineering</div>
        <div class="bar1"></div>
        <div class="head2">Photography</div>
        <div class="bar2"></div>
        <div class="head2">Creative Designs</div>
        <div class="bar3"></div>
      </div>
    </div>
    <div class="box">
      <div class="indiv">
        <div class="head">Programming: </div>
        <div class="sklist1">
          <span>C++/C</span><span>Python</span><span>Java</span>
        </div>
      </div>
      <div class="indiv">
        <div class="head">Web: </div>
        <div class="sklist2">
          <span>VueJS</span><span>Flask</span><span>Django</span><span>HTML</span><span>CSS</span><span>Javascript</span>
        </div>
      </div>
      <div class="indiv">
        <div class="head">Analytics: </div>
        <div class="sklist3">
          <span>SQL</span><span>Excel/VBA</span><span>Tensorflow</span><span>Keras</span><span>PowerBI</span>
        </div>
      </div>
      <div class="indiv">
        <div class="head">Creative: </div>
        <div class="sklist4">
          <span>Photoshop</span><span>Illustrator</span><span>Premiere Pro</span><span>Cinema 4D</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Skills',
  data() {
    return {
      showemail: false,
    };
  },
  methods: {
    link1() {
      window.open("https://linkedin.com/in/richie-ang");
    },
    showe() {
      this.showemail = !this.showemail;
    },
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
body, html {
  height: 100%;
}
.skill {
  cursor: default;
}
.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.2vw;
  color: #1882b4;
  left: 0;
  text-align: left;
  padding-left: 6vw;
  padding-top: 16vh;
  cursor: default;
}
.mainbody {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bodytext {
  padding-top: 2%;
  width: 50%;
  animation: fadein 0.5s;
  animation-fill-mode: forwards;
}
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1 }
}
.pchart {
  width: 50%;
}
p {
  font-family: 'Montserrat', sans-serif;
  color: #1882b4;
  text-align: left;
  font-size: 1.1vw;
  line-height: 120%;
  padding-left: 6vw;
  padding-right: 2%;
  margin-top: 1%;
}
p em {
  color: #e6a282;
  font-style: normal;
  cursor: pointer;
}
.indiv {
  font-family: 'Montserrat', sans-serif;
  color: #1882b4;
  text-align: left;
  font-size: 1.1vw;
  padding-left: 6vw;
  display: flex;
  padding-top: 1.5vw;
}
.head2 {
  font-family: 'Montserrat', sans-serif;
  color: #1882b4;
  text-align: left;
  font-size: 1.1vw;
  padding-left: 4vw;
  margin-top: 0.7vw;
}
.sklist1,
.sklist2,
.sklist3,
.sklist4 {
  display: flex;
  align-items: center;
}
.sklist1 span {
  background: #75bee0;
  margin-left: 1vw;
  margin-left: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 1vw;
  color: white;
  font-size: 0.9vw;
}
.sklist1 span:hover {
  background: #64a9c9;
}
.sklist2 span {
  background: #6d91d3;
  margin-left: 1vw;
  margin-left: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 1vw;
  color: white;
  font-size: 0.9vw;
}
.sklist2 span:hover{
  background: #5e82c4;
}
.sklist3 span {
  background: #6f6dd3;
  margin-left: 1vw;
  margin-left: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 1vw;
  color: white;
  font-size: 0.9vw;
}
.sklist3 span:hover{
  background: #5c5bc2;
}
.sklist4 span {
  background: #6dbfd3;
  margin-left: 1vw;
  margin-left: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 1vw;
  color: white;
  font-size: 0.9vw;
}
.sklist4 span:hover{
  background: #5cadc2;
}
.bar1 {
  margin-top: 0.3vw;
  background: rgb(71,152,198);
  background: -moz-linear-gradient(150deg, rgba(71,152,198,1) 0%, rgba(85,83,182,1) 100%);
  background: -webkit-linear-gradient(150deg, rgba(71,152,198,1) 0%, rgba(85,83,182,1) 100%);
  background: linear-gradient(150deg, rgba(71,152,198,1) 0%, rgba(85,83,182,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4798c6",endColorstr="#5553b6",GradientType=1);
  height: 1.3vw;
  width: 0vw;
  margin-left: 4vw;
  border-radius: 1vw;
  animation: expandbar1 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation: expandbar1 1s forwards;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes expandbar1 {
  0% { width: 0; }
  100% { width: 25vw; }
}
.bar1:hover {
  animation: expandbar1 1s 1, expandbar11 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes expandbar11 {
  0% { width: 30vw; }
  30% { width: 21vw; }
  60% { width: 27vw;}
  100% { width: 25vw; }
}
.bar2 {
  margin-top: 0.3vw;
  background: rgb(71,187,198);
  background: -moz-linear-gradient(150deg, rgba(71,187,198,1) 0%, rgba(30,72,106,1) 100%);
  background: -webkit-linear-gradient(150deg, rgba(71,187,198,1) 0%, rgba(30,72,106,1) 100%);
  background: linear-gradient(150deg, rgba(71,187,198,1) 0%, rgba(30,72,106,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47bbc6",endColorstr="#1e486a",GradientType=1);
  height: 1.3vw;
  width: 0vw;
  margin-left: 4vw;
  border-radius: 1vw;
  animation: expandbar2 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation: expandbar2 1s forwards;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes expandbar2 {
  0% { width: 0; }
  100% { width: 19vw; }
}
.bar2:hover {
  animation: expandbar2 1s 1, expandbar22 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes expandbar22 {
  0% { width: 24vw; }
  30% { width: 15vw; }
  60% { width: 21vw;}
  100% { width: 19vw; }
}
.bar3 {
  margin-top: 0.3vw;
  background: rgb(71,112,198);
  background: -moz-linear-gradient(150deg, rgba(71,112,198,1) 0%, rgba(85,22,101,1) 100%);
  background: -webkit-linear-gradient(150deg, rgba(71,112,198,1) 0%, rgba(85,22,101,1) 100%);
  background: linear-gradient(150deg, rgba(71,112,198,1) 0%, rgba(85,22,101,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4770c6",endColorstr="#551665",GradientType=1);
  height: 1.3vw;
  width: 0vw;
  margin-left: 4vw;
  border-radius: 1vw;
  animation: expandbar3 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation: expandbar3 1s forwards;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes expandbar3 {
  0% { width: 0; }
  100% { width: 23vw; }
}
.bar3:hover {
  animation: expandbar3 1s 1, expandbar33 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes expandbar33 {
  0% { width: 28vw; }
  30% { width: 19vw; }
  60% { width: 25vw;}
  100% { width: 23vw; }
}
.box {
  margin-left: 6vw;
  padding-left: -5vw;
  height: 14vw;
  box-shadow: 5px 5px 10px 2px rgba(235, 235, 235, 0.8);
  width: 50vw;
  opacity: 0;
  animation: slideinleft 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes slideinleft {
  0% { 
    margin-left: -25vw;
    opacity: 0;
  }
  100% { 
    margin-left: 6vw;
    opacity: 1;
  }
}
@media (max-width: 900px) {
.title {
  padding-top: 25vh;
  font-size: 4vw;
}
.bodytext {
  padding-top: 2%;
  width: 75vw;
}
.pchart {
  width: 75vw;
}
p {
  font-family: 'Montserrat', sans-serif;
  color: #1882b4;
  text-align: left;
  font-size: 2.5vw;
  line-height: 120%;
  padding-left: 2vw;
  margin-top: 1%;
}
.mainbody {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.head2 {
  text-align: left;
  font-size: 2.2vw;
  margin-top: 0.7vw;
}
.bar1 {
  margin-top: 0.3vw;
  height: 2vw;
  width: 0vw;
  border-radius: 1vw;
  animation: expandbar1 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation: expandbar1 1s forwards;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes expandbar1 {
  0% { width: 0; }
  100% { width: 60vw; }
}
.bar1:hover {
  animation: expandbar1 1s 1, expandbar11 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes expandbar11 {
  0% { width: 65vw; }
  30% { width: 56vw; }
  60% { width: 62vw;}
  100% { width: 60vw; }
}
.bar2 {
  margin-top: 0.3vw;
  height: 2vw;
  width: 0vw;
  margin-left: 4vw;
  border-radius: 1vw;
  animation: expandbar2 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation: expandbar2 1s forwards;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes expandbar2 {
  0% { width: 0; }
  100% { width: 47vw; }
}
.bar2:hover {
  animation: expandbar2 1s 1, expandbar22 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes expandbar22 {
  0% { width: 52vw; }
  30% { width: 43vw; }
  60% { width: 49vw;}
  100% { width: 47vw; }
}
.bar3 {
  margin-top: 0.3vw;
  height: 2vw;
  width: 0vw;
  animation: expandbar3 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation: expandbar3 1s forwards;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-iteration-count: 1;
}
@keyframes expandbar3 {
  0% { width: 0; }
  100% { width: 54vw; }
}
.bar3:hover {
  animation: expandbar3 1s 1, expandbar33 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes expandbar33 {
  0% { width: 59vw; }
  30% { width: 50vw; }
  60% { width: 56vw;}
  100% { width: 54vw; }
}
.box {
  margin-left: 6vw;
  margin-top: 6vw;
  margin-bottom: 6vw;
  height: 20vw;
  width: 73vw;
  animation: slideinleft 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.head {
  font-size: 2vw;
}
.sklist1 span {
  font-size: 1.9vw;
}
.sklist2 span {
  font-size: 1.9vw;
}
.sklist3 span {
  font-size: 1.9vw;
}
.sklist4 span {
  font-size: 1.9vw;
}
}
</style>
