<template>
  <div class="art">
    <div v-show="!loader" class="overlay">
      <div class="imagelist1">
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph1"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph2"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph4"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph3"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph12"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph16"></b-img>
      </div>
    </div>
    <div v-show="!loader" class="overlay2">
      <div class="imagelist2">
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph6"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph7"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph8"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph9"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph13"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph10"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph14"></b-img>
        <b-img @load="handleLoad"  @error="handleLoad" class="phototn" fluid :src="phlist.ph15"></b-img>
      </div>
    </div>
    <div v-show="loader" class="overlay3">
      <div class="loader">
        <img :src="phlist.ph11"/>
      </div>
    </div>
    <div class="topbox"></div>
    <div class="middlebh">
      <div class="wordarea">Photography</div>
      <div class="wordarea">& Digital Art</div>
      <div class="wordarea2">All rights belong to Richie</div>
    </div>
    <div class="botbox"></div>
    
  </div>
</template>

<script>
import ph1 from "./art/DSC04093.jpg"
import ph2 from "./art/DSC04854.jpg"
import ph3 from "./art/DSC05395.jpg"
import ph4 from "./art/DSC05435.jpg"
import ph5 from "./art/DSC01340.jpg"
import ph6 from "./art/DSC01833.jpg"
import ph7 from "./art/DSC02568.jpg"
import ph8 from "./art/DSC03570-2.jpg"
import ph9 from "./art/DSC04607-2.jpg"
import ph10 from "./art/DSC04621-2.jpg"
import ph11 from "./art/Infinity-1.2s-221px.svg"
import ph12 from "./art/JokerMask.png"
import ph13 from "./art/UnderTheMoonFinal.png"
import ph14 from "./art/DSC04162-3.jpg"
import ph15 from "./art/DSC02391.jpg"
import ph16 from "./art/DSC04325-3.jpg"

export default {
  name: 'Art',
  data() {
    return {
      phlist: { ph1: ph1, ph2: ph2, ph3: ph3, ph4: ph4, ph5: ph5, 
      ph6: ph6, ph7: ph7, ph8: ph8, ph9: ph9, ph10: ph10, 
      ph11: ph11, ph12: ph12, ph13: ph13, ph14: ph14, ph15: ph15,
      ph16: ph16 },
      imgLoaded: 0,
      loader: true,
    }
  },
  methods: {
    handleLoad() {
      this.imgLoaded++;
      if(this.imgLoaded == 14) {
         setTimeout(() => this.loader = false, 1000);
      }
    },
    pageScroll() {
      window.scrollBy(0,1);
      setTimeout(this.pageScroll,10)
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
body, html {
  height: 100%;
}
.art {
  width: 100%;
}
.topbox {
  height: 3vh;
  background: #2f3541;
}
.middlebh {
  height: 94vh;
  display: flex;
  justify-content: center;
  padding-left: 5vw;
  flex-direction: column;
}
.botbox {
  height: 3vh;
  background: #2f3541;
}
.wordarea {
  font-family: 'Anton', sans-serif;
  font-size: 3vw;
  float: left;
  text-align: left;
}
.wordarea2 {
  font-family: 'Anton', sans-serif;
  font-size: 1vw;
  float: left;
  text-align: left;
}
.overlay {
  position: absolute;
  z-index: 2;
  overflow: scroll;
  height: 100vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.overlay::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.overlay2 {
  position: absolute;
  z-index: 3;
  width: 30vw;
  margin-left: 55vw;
  overflow: scroll;
  height: 100vh;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.overlay2::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.overlay3 {
  position: absolute;
  z-index: 3;
  width: 30vw;
  margin-left: 43vw;
}
.loader {
  width: 25vw;
  padding-top: 35vh;
}
.imagelist1 {
  margin-left: 28vw;
  width: 25vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.imagelist2 {
  width: 25vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.phototn {
  margin-top: 2vw;
  width: 30vw;
}
@media (max-width: 900px) {

.imagelist2 {
  width: 62vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.phototn {
  margin-top: 2vw;
  width: 75vw;
}
.overlay2 {
  width: 60vw;
  margin-left: 25vw;
}
.overlay3 {
  margin-left: 27vw;
}
.overlay {
 display: none;
}
.middlebh {
  height: 94vh;
  display: flex;
  opacity: 1;
  justify-content: center;
  padding-left: 5vw;
  flex-direction: column;
}
}
</style>
