<template>
  <div class="proj">
    <div @click="showpan" v-show="showp" class="backboard"></div>
    <div @click="showpan" v-show="showp" class="toppanel">
      <div v-show="pdetails.pd1" class="defpanel">
        <div class="wkarea">
          <div class="footnote">[ Click Anywhere To Close ]</div>
          <div class="pheader">Ecommerce Inventory Manager</div>
          <div class="rowcover">
            <div class="row">
              <div class="pjimg"><div class="aimg"><b-img fluid :src="dproj.ppimg1" ></b-img></div></div>
              <div class="desc"><div class="desctext" style="padding-top:2vw;">
                <p>
                  As part of a school project, my team and I were tasked to produce either
                  a web or mobile application that makes use of existing data apis to develop
                  a working prototype that improves business or lifestyle.
                </p>
                <p>
                  As a team, we decided to improve the ecommerce market. We wanted a tool
                  that helps to manage inventory across multiple ecommerce platforms and
                  sync up the items that were displayed across the different platforms. That was 
                  the idea that brought this working ecommerce inventory manager to life.
                </p>
              </div></div>
            </div>
            <div class="row">
              <div class="desc"><div class="desctext" style="padding-top:2vw;">
                <p>Features:</p>
                <p>1. Secure and encrypted system for account registration and login</p>
                <p style="margin-top: -1em;line-height:1.2em;">2. Simple and fast process for integration of new ecommerce platforms to the 
                application with support for major ecommerce sites</p>
                <p style="margin-top: -1em;line-height:1.2em;">3. Advanced search bar for quick identification of products and their respective
                information</p>
                <p style="margin-top: -1em;line-height:1.2em;">4. Allows for the update, alteration and deletion of products across the multiple
                platforms</p>
                <p style="margin-top: -1em;line-height:1.2em;">5. Scalable for new products and ecommerce sites</p>
              </div></div>
              <div class="pjimg"><div class="aimg ssimg" style="padding-right:2vw;padding-left:0;"><b-img fluid :src="dproj.ppimg2" ></b-img></div></div>
            </div>
            <div class="row">
              <div class="pjimg"><div class="aimg" style="padding-bottom:5vw;"><b-img fluid :src="dproj.ppimg3" ></b-img></div></div>
              <div class="desc"><div class="desctext" style="padding-top:2vw;">
                <p>
                  Being the team leader, my main role was ensuring that the team
                  is able to complete our weekly objectives set out. However in terms of coding,
                  I was in charge of the front-end developments and the integration with the 
                  backend developers.
                </p>
                <p>
                  Despite the team being new to the web development and vueJS framework,
                  we managed to pull through with a working application within 10 weeks. The 
                  application met all of our objectives and feature requirement set out by 
                  ourselves.
                </p>
                <p> 
                  For more information on the project, it is available as an open source
                  application at my <em @click="opengh">GitHub</em>
                </p>
              </div></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="pdetails.pd2" class="defpanel">
        <div class="wkarea">
          <div class="footnote">[ Click Anywhere To Close ]</div>
          <div class="pheader">Portfolio Website</div>
          <div class="rowcover">
            <div class="row">
              <div class="pjimg"><div class="aimg"><b-img fluid :src="dproj.ppimg4" ></b-img></div></div>
              <div class="desc"><div class="desctext" style="padding-top:5vw;">
                <p>
                  This website is the portfolio website.
                </p>
                <p>
                  It has always been a dream to have my own website, to showcase the different 
                  projects that I have worked on. This allows for my own imaginative mind to come
                  out with the design and format of the website.
                </p>
              </div></div>
            </div>
            <div class="row">
              <div class="desc"><div class="desctext" style="padding-top:2vw;">
                <p style="padding-top:2vw;">
                  Photoshop is used for the majority of the planning stage. On the right is 
                  the original draft of the website, as you can probably tell, a lot of the design
                  has been changed from the original.
                </p>
                <p>
                  This website is build entirely from scratch with <em @click="openVue">VueJS framework</em>. To prevent malicious attempts on the website,
                  the source code will remain private.
                </p>
                <p>Continue to explore the website itself!</p>
              </div></div>
              <div class="pjimg"><div class="aimg ssimg" style="padding-right:2vw;padding-left:0;"><b-img fluid :src="dproj.ppimg5" ></b-img></div></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="pdetails.pd3" class="defpanel">
        <div class="wkarea">
          <div class="footnote">[ Click Anywhere To Close ]</div>
          <div class="pheader">Excel VBA Projects</div>
          <div class="rowcover">
            <div class="row">
              <div class="pjimg"><div class="aimg"><b-img fluid :src="dproj.ppimg6" ></b-img></div></div>
              <div class="desc"><div class="desctext" style="padding-top:2vw;">
                <p>
                  All projects listed here are part of my job resposibilities as a RPA Developer.
                  Private and personal data has been withheld, and only dummy data are used
                </p>
                <p>
                  For the projects, I closely interact with the directors of the company to identify areas of process automation
                  with VBA/Python tools. I employ the use of <em @click="agilewiki">AGILE methodology</em> and discuss with the directors regularly to 
                  ensure the programs are on the right track. Most of the projects are made with productivity,
                  dynamicability and user experience as key objectives.
                </p>
              </div></div>
            </div>
            <div class="row">
              <div class="desc"><div class="desctext" style="padding-top:5vw;">
                <p>Major Projects Completed:</p>
                <p>1. Monthly Invoice Processor [Includes error checker, capital checker, and Xero formatting]</p>
                <p style="margin-top: -1em;line-height:1.2em;">2. Project Analysis & Projection Tool (2nd picture)</p>
                <p style="margin-top: -1em;line-height:1.2em;">3. Corporate Secretary Document Generator (3rd picture)</p>
                <p style="margin-top: -1em;line-height:1.2em;">4. Python Web Scrapping Tool</p>
                <p style="margin-top: -1em;line-height:1.2em;">5. IT Support Automated Chatbot</p>
              </div></div>
              <div class="pjimg"><div class="aimg ssimg" style="padding-right:2vw;padding-left:0;"><b-img fluid :src="dproj.ppimg7" ></b-img></div></div>
            </div>
            <div class="row">
              <div class="pjimg"><div class="aimg" style="padding-bottom:5vw;"><b-img fluid :src="dproj.ppimg8" ></b-img></div></div>
              <div class="desc"><div class="desctext" style="padding-top:5vw;">
                <p>
                  I arrived without any experience in VBA programming, but with the trust of the directors,
                  I managed to produce the Excel/VBA tools of their requirement and have learned a great deal about the company's
                  integration as a whole.
                </p>
                <p>
                  I am always open to new challenges and experiences to develop myself professionally.
                </p>
              </div></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="pdetails.pd4" class="defpanel">
        <div class="wkarea">
          <div class="footnote">[ Click Anywhere To Close ]</div>
          <div class="pheader">Reservation & Point-Of-Sale System</div>
          <div class="rowcover">
            <div class="row">
              <div class="desc"><div class="desctext" style="padding-top:5vw;">
                <p>
                  Work in progress...
                </p>
              </div></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="pdetails.pd5" class="defpanel">
        <div class="wkarea">
          <div class="footnote">[ Click Anywhere To Close ]</div>
          <div class="pheader">Windows Font Organiser</div>
          <div class="rowcover">
            <div class="row">
              <div class="desc"><div class="desctext" style="padding-top:5vw;">
                <p>
                  Work in progress...
                </p>
              </div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">Project</div>
    <div class="workarea">
      <div class="projpanel anim1" @click="pdis1" @mouseover="uclist.uncover1 = true" @mouseleave="uclist.uncover1 = false" v-bind:class="{ shadowdark: uclist.uncover1, shadow: !uclist.uncover1 }">
        <div class="inner">
          <div class="left">
            <transition name="fade">
              <b-img fluid @load="handleLoad" v-show="imgShow" :src="pimageslist.pimg1" ></b-img>
            </transition>
          </div>
          <div class="right">
            <div class="smallword">2021</div>
            <div class="pname">Ecommerce Inventory Manager</div>
            <div class="smallword">[Software Engineering]</div>
            <div class="smallword" style="padding-top:5.2vw">Tools : {{ tnlist.toolname1 }}</div>
            <div class="imgrow">
              <b-img @mouseover="tnlist.toolname1 = 'VueJS'" @mouseleave="tnlist.toolname1 = ''" class="toolimg" fluid :src="imageslist.img1" ></b-img>
              <b-img @mouseover="tnlist.toolname1 = 'SQLite'" @mouseleave="tnlist.toolname1 = ''" class="toolimg" fluid :src="imageslist.img2" ></b-img>
              <b-img @mouseover="tnlist.toolname1 = 'Flask'" @mouseleave="tnlist.toolname1 = ''" class="toolimg" fluid :src="imageslist.img3" ></b-img>
            </div>
          </div>
        </div>
      </div>
      <div class="projpanel anim2" @click="pdis2" @mouseover="uclist.uncover2 = true" @mouseleave="uclist.uncover2 = false" v-bind:class="{ shadowdark: uclist.uncover2, shadow: !uclist.uncover2 }">
        <div class="inner">
          <div class="left">
            <transition name="fade">
              <b-img fluid @load="handleLoad" v-show="imgShow" :src="pimageslist.pimg2" ></b-img>
            </transition>
          </div>
          <div class="right">
            <div class="smallword">2022</div>
            <div class="pname">Portfolio Website</div>
            <div class="smallword">[Web Development]</div>
            <div class="smallword" style="padding-top:6.2vw;">Tools : {{ tnlist.toolname2 }}</div>
            <div class="imgrow">
              <b-img @mouseover="tnlist.toolname2 = 'VueJS'" @mouseleave="tnlist.toolname2 = ''" class="toolimg" fluid :src="imageslist.img1" ></b-img>
              <b-img @mouseover="tnlist.toolname2 = 'Photoshop'" @mouseleave="tnlist.toolname2 = ''" class="toolimg" fluid :src="imageslist.img4" ></b-img>
              <b-img @mouseover="tnlist.toolname2 = 'Bootstrap'" @mouseleave="tnlist.toolname2 = ''" class="toolimg" fluid :src="imageslist.img14" ></b-img>
            </div>
          </div>
        </div>
      </div>
      <div class="projpanel anim3" @click="pdis3" @mouseover="uclist.uncover3 = true" @mouseleave="uclist.uncover3 = false" v-bind:class="{ shadowdark: uclist.uncover3, shadow: !uclist.uncover3 }">
        <div class="inner">
          <div class="left">
            <transition name="fade">
              <b-img fluid @load="handleLoad" v-show="imgShow" :src="pimageslist.pimg5" ></b-img>
            </transition>
          </div>
          <div class="right">
            <div class="smallword">2021</div>
            <div class="pname">Excel VBA Projects</div>
            <div class="smallword">[RPA Software Engineering]</div>
            <div class="smallword" style="padding-top:6.2vw;">Tools : {{ tnlist.toolname3 }}</div>
            <div class="imgrow">
              <b-img @mouseover="tnlist.toolname3 = 'Python'" @mouseleave="tnlist.toolname3 = ''" class="toolimg" fluid :src="imageslist.img5" ></b-img>
              <b-img @mouseover="tnlist.toolname3 = 'Microsoft VBA'" @mouseleave="tnlist.toolname3 = ''" class="toolimg" fluid :src="imageslist.img6" ></b-img>
              <b-img @mouseover="tnlist.toolname3 = 'Power Automate'" @mouseleave="tnlist.toolname3 = ''" class="toolimg" fluid :src="imageslist.img7" ></b-img>
              <b-img @mouseover="tnlist.toolname3 = 'Excel'" @mouseleave="tnlist.toolname3 = ''" class="toolimg" fluid :src="imageslist.img8" ></b-img>
              <b-img @mouseover="tnlist.toolname3 = 'Ui Path'" @mouseleave="tnlist.toolname3 = ''" class="toolimg" fluid :src="imageslist.img13" ></b-img>
            </div>
          </div>
        </div>
      </div>
      <div class="projpanel anim4" @click="pdis4" @mouseover="uclist.uncover4 = true" @mouseleave="uclist.uncover4 = false" v-bind:class="{ shadowdark: uclist.uncover4, shadow: !uclist.uncover4 }">
        <div class="inner">
          <div class="left">
            <transition name="fade">
             <b-img fluid @load="handleLoad" v-show="imgShow" :src="pimageslist.pimg4" ></b-img>
            </transition>
          </div>
          <div class="right">
            <div class="smallword">2021</div>
            <div class="pname">Reservation & Point-Of-Sale System</div>
            <div class="smallword">[Software Engineering]</div>
            <div class="smallword" style="padding-top:5.2vw">Tools : {{ tnlist.toolname4 }}</div>
            <div class="imgrow">
              <b-img @mouseover="tnlist.toolname4 = 'Java'" @mouseleave="tnlist.toolname4 = ''" class="toolimg" fluid :src="imageslist.img9" ></b-img>
              <b-img @mouseover="tnlist.toolname4 = 'Eclipse IDE'" @mouseleave="tnlist.toolname4 = ''" class="toolimg" fluid :src="imageslist.img10" ></b-img>
            </div>
          </div>
        </div>
      </div>
      <div class="projpanel anim5" @click="pdis5" @mouseover="uclist.uncover5 = true" @mouseleave="uclist.uncover5 = false" v-bind:class="{ shadowdark: uclist.uncover5, shadow: !uclist.uncover5 }">
        <div class="inner">
          <div class="left">
            <transition name="fade">
             <b-img fluid @load="handleLoad" v-show="imgShow" :src="pimageslist.pimg5" ></b-img>
            </transition>
          </div>
          <div class="right">
            <div class="smallword">2018</div>
            <div class="pname">Windows Font Organiser</div>
            <div class="smallword">[Software Engineering]</div>
            <div class="smallword" style="padding-top:5.2vw">Tools : {{ tnlist.toolname5 }}</div>
            <div class="imgrow">
              <b-img @mouseover="tnlist.toolname5 = 'C++'" @mouseleave="tnlist.toolname5 = ''" class="toolimg" fluid :src="imageslist.img11" ></b-img>
              <b-img @mouseover="tnlist.toolname5 = 'Visual Studio'" @mouseleave="tnlist.toolname5 = ''" class="toolimg" fluid :src="imageslist.img12" ></b-img>
            </div>
          </div>
        </div>
      </div>
      <div class="projpanel shadow anim6"  @click="pdis6" style="opacity:0">
        <div class="emptymsg" v-show="pdetails.pd6">More to come</div>
      </div>
      <div class="projpanel shadow anim7"  @click="pdis7" style="opacity:0">
        <div class="emptymsg" v-show="pdetails.pd7">More to come</div>
      </div>
    </div>
  </div>
</template>

<script>
import pimg1 from "./component_assets/ThumbnailMgr.jpg"
import img1 from "./component_assets/vuekjs.png"
import img2 from "./component_assets/sqlite.png"
import img3 from "./component_assets/flask.png"
import img4 from "./component_assets/ps.png"
import img5 from "./component_assets/python.png"
import img6 from "./component_assets/vba.png"
import img7 from "./component_assets/pwauto.png"
import img8 from "./component_assets/excel.png"
import img9 from "./component_assets/java.png"
import img10 from "./component_assets/eclipse.png"
import img11 from "./component_assets/cpp.png"
import img12 from "./component_assets/vsc.png"
import img13 from "./component_assets/ui.png"
import img14 from "./component_assets/bootstrap.jpg"
import pimg2 from "./component_assets/websitethumbnail.jpg"
import pimg4 from "./component_assets/RestAppThumbnail.jpg"
import ppimg1 from "./component_assets/1.png"
import ppimg2 from "./component_assets/2.png"
import ppimg3 from "./component_assets/3.png"
import ppimg4 from "./component_assets/4.jpg"
import ppimg5 from "./component_assets/5.jpg"
import ppimg6 from "./component_assets/6.jpg"
import ppimg7 from "./component_assets/7.jpg"
import ppimg8 from "./component_assets/8.jpg"
import pimg5 from "./component_assets/imgnotavailable.jpg"

export default {
  name: 'Projects',
  data() {
    return {
      uclist: { uncover1: false, uncover2: false, uncover3: false, uncover4: false, uncover5: false},
      tnlist: { toolname1: "", toolname2: "", toolname3: "", toolname4: "", toolname5: ""},
      pimageslist: { pimg1: pimg1, pimg2: pimg2, pimg4: pimg4, pimg5: pimg5},
      showp: false,
      pdetails: { pd1: false, pd2: false, pd3: false, pd4: false, pd5: false, pd6: false, pd7: false},
      imageslist: { img1: img1, img2: img2, img3: img3, img4: img4, img5: img5, img6: img6, img7: img7,
      img8: img8, img9: img9, img10: img10, img11: img11, img12: img12, img13: img13, img14: img14 },
      dproj: {ppimg1: ppimg1, ppimg2: ppimg2, ppimg3: ppimg3, ppimg4: ppimg4, ppimg5: ppimg5, ppimg6: ppimg6, ppimg7: ppimg7, ppimg8: ppimg8},
      imgLoaded: 0,
      imgShow: false,
    }
  },
  methods: {
    openVue() {
      window.open("https://vuejs.org/")
    },
    agilewiki() {
      window.open("https://en.wikipedia.org/wiki/Agile_software_development")
    },
    opengh() {
      window.open("https://github.com/richieang002/Ecommerce-Inventory-Manager");
    },
    handleLoad() {
      this.imgLoaded++;
      if(this.imgLoaded == 5) {
         setTimeout(() => this.imgShow = true, 500);
      }
    },
    showpan() {
      this.showp = false;
      this.pdetails.pd1 = false;
      this.pdetails.pd2 = false;
      this.pdetails.pd3 = false;
      this.pdetails.pd4 = false;
      this.pdetails.pd5 = false;
    },
    pdis1() {
      this.showp = true;
      this.pdetails.pd1 = true;
    },
    pdis2() {
      this.showp = true;
      this.pdetails.pd2 = true;
    },
    pdis3() {
      this.showp = true;
      this.pdetails.pd3 = true;
    },
    pdis4() {
      this.showp = true;
      this.pdetails.pd4 = true;
    },
    pdis5() {
      this.showp = true;
      this.pdetails.pd5 = true;
    },
    pdis6() {
      this.pdetails.pd6 = true;
      setTimeout(() => this.pdetails.pd6 = false, 2000);
    },
    pdis7() {
      this.pdetails.pd7 = true;
      setTimeout(() => this.pdetails.pd7 = false, 2000);
    },
  },
  mounted () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
body, html {
  height: 100%;
}
.proj {
  height:100%;
  left: 0;
  width:100%;
}
.shadow {
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
}
.shadowdark {
  box-shadow: 5px 5px 10px 2px rgba(170, 170, 170, 0.8);
}
@font-face {
  font-family: "Haettenschweiler";
  src: local("Haettenschweiler"),
   url(./fonts/Haettenschweiler-Regular.ttf) format("truetype");
}
.title {
  font-family: Haettenschweiler;
  font-size: 4vw;
  color: #7accc8;
  left: 0;
  text-align: left;
  padding-left: 9vw;
  padding-top: 3%;
  cursor: default;
}
.toolimg {
  width: 2vw;
  margin-right: 7px;
}
.imgrow {
  display:flex;
  justify-content: flex-start;
}
.smallword {
  font-size: 1vw;
  text-align:left;
  color: #787878;
}
.pname {
  line-height: 1.2vw;
  font-size: 1.3vw;
  text-align:left;
  color: #51a7a2;
}
.workarea {
  display: flex;
  justify-content:flex-start;
  height: 100%;
  width: 100%;
  padding-left: 6%;
  flex-wrap: wrap;
}
.projpanel {
  height: 17vw;
  width: 33vw;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
  border-radius: 0.6vw;
  opacity: 0;
  cursor: pointer;
}
.inner {
  margin-left: 1vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  width: 95%;
  display: flex;
  justify-content: flex-start;
}
.left {
  height: 14vw;
  width: 14vw;
  background: #7accc8;
  border-radius: 0.5vw;
}
.right {
  width: 16vw;
  height: 13vw;
  font-family: 'Montserrat', sans-serif;
  padding-left: 1.5vw
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s; 
}
.anim1 {
  animation: opappear 0.8s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}
.anim2 {
  animation: opappear 0.8s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}
.anim3 {
  animation: opappear 0.8s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
.anim4 {
  animation: opappear 0.8s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}
.anim5 {
  animation: opappear 0.8s; 
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}
.anim6 {
  animation: opappear 0.8s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.anim7 {
  animation: opappear 0.8s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}
@keyframes opappear {
  0% { opacity: 0.3; }
  100% { opacity: 1; }
}
@keyframes opappear6 {
  0% { opacity: 0.3; }
  100% { opacity: 0.8; }
}
@keyframes opappear7 {
  0% { opacity: 0.3; }
  100% { opacity: 0.7; }
}
.backboard {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 4;
  background: black;
  opacity: 0.5;
}
.toppanel {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction:column;
}
.defpanel {
  margin-top: 5vw;
  margin-left: 7vw;
  width: 70vw;
  background: #d5ebe9;
  animation: opappear 0.3s;
  animation-fill-mode: forwards;
  border-radius: 0.5vw;
}
.emptymsg {
  font-family: 'Montserrat', sans-serif;
  padding-top: 20%;
  color: #7accc8;
  opacity: 0;
  animation: fadeinout 1.5s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}
@keyframes fadeinout {
  0% { opacity: 0; }
  30% { opacity: 1; }
  70% { opacity: 1; }
  100% { opacity: 0; }
}
.wkarea {
  font-family: 'Montserrat', sans-serif;
  color: #2c7d79;
}
.footnote {
  text-align: left;
  font-size: 0.8vw;
  margin-left: 3vw;
  padding-top: 1vw;
}
.pheader {
  color: #60b1ad;
  font-size: 2.5vw;
}
.row {
  display: flex;
}
.rowcover {
  background: rgb(241, 247, 247);
  border-radius: 1vw;
}
.pjimg {
  width: 35vw;
}
.aimg {
  width: 35vw;
  padding-left: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
}
.desc {
  width: 35vw;
}
.desctext {
  padding-left: 2vw;
  text-align: left;
  font-size: 0.9vw;
}
em {
  color: #ffa57b;
  font-style: normal;
  cursor: pointer;
}
@media (max-width: 900px) {
.title {
  padding-top: 10vh;
  font-size: 10vw;
}
.projpanel {
  height: 36vw;
  width: 73vw;
}
.toolimg {
  width: 6vw;
  margin-right: 7px;
}
.smallword {
  font-size: 2.5vw;
  text-align:left;
  color: #787878;
}
.pname {
  line-height: 2.2vw;
  font-size: 2.8vw;
  text-align:left;
  color: #51a7a2;
}
.left {
  height: 30vw;
  width: 30vw;
  background: #7accc8;
  border-radius: 2vw;
  margin-top: 2%;
}
.right {
  width: 38vw;
  height: 13vw;
  font-family: 'Montserrat', sans-serif;
  padding-left: 1.5vw
}
.footnote {
  text-align: left;
  font-size: 1.5vw;
  margin-left: 3vw;
  padding-top: 1vw;
}
.pheader {
  color: #60b1ad;
  font-size: 3.5vw;
}
.row {
  display: flex;
  flex-direction: column;
}
.rowcover {
  margin-top: 5%;
  padding-top: 5%;
  border-radius: 1vw;
}
.pjimg {
  width: 75vw;
}
.aimg {
  width: 65vw;
  padding-left: 3vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
}
.desc {
  width: 72vw;
}
.desctext {
  padding-left: 3.5vw;
  text-align: left;
  font-size: 2vw;
}
em {
  color: #ffa57b;
  font-style: normal;
  cursor: pointer;
}
.ssimg {
  margin-left: 3vw;
}
}
</style>
