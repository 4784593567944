import Vue from 'vue'
import App from './App.vue'
import PortalVue from 'portal-vue'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import VueRouter from 'vue-router'
import Particles from "particles.vue";
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(Particles);
Vue.use(VueRouter)
Vue.use(BootstrapVue);
Vue.use(PortalVue)
Vue.config.productionTip = false


import Home from '@/components/Home.vue'
import Menu from '@/components/Menu.vue'
import About from '@/components/About.vue'
import Projects from '@/components/Projects.vue'
import Skills from '@/components/Skills.vue'
import Art from '@/components/Art.vue'

const router = new VueRouter({
  mode: 'abstract',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/menu',
      name: 'Menu',
      component: Menu,
      children: [
        {
          path: '/about',
          name: 'About',
          component: About,
        },
        {
          path: '/projects',
          name: 'Projects',
          component: Projects,
        },
        {
          path: '/skills',
          name: 'Skills',
          component: Skills,
        },
        {
          path: '/art',
          name: 'Art',
          component: Art,
        },
      ]
    },
  ],
})

new Vue({
  router,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')

router.replace('/')
export default router