<template>
  <div class="menu">
    <div v-if="isActive" class="white-box">
      <transition name="fade">
        <div v-if="isActive2" class="row">
          <span @click="unbind1" v-bind:class="{ style1: style1, spans1: defaultspan1 }">About Me</span>
          <span @click="unbind2" v-bind:class="{ style2: style2, spans2: defaultspan2 }">Projects</span>
          <div class="midbox">
            <div @click="homepage" class="rectbox shadow">
              Home
            </div>
          </div>
          <span @click="unbind3" v-bind:class="{ style3: style3, spans3: defaultspan3 }">Skills</span>
          <span @click="unbind4" v-bind:class="{ style4: style4, spans4: defaultspan4 }">Art Life</span>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div v-if="isActive2" class="bblock"></div>
    </transition>
    <div class="white-box-offset">
    </div>
    <div class="offsetleft">
      <router-view  v-slot="{ Component }">
        <tansition name="slide">
          <component :is="Component" />
        </tansition>
      </router-view>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'Menu',
  data() {
    return {
      isActive: false,
      isActive2: false,
      style1: false,
      style2: false,
      style3: false,
      style4: false,
      defaultspan1: true,
      defaultspan2: true,
      defaultspan3: true,
      defaultspan4: true,
      delay: "1s",
    }
  },
  mounted() {
    let data = this.$route.params.data;
    this.isActive = true;
    var self = this;
    setTimeout(function(){ 
      self.isActive2 = true;
    }, 1000);
    if (data == 1) {
      this.style1 = true;
      this.defaultspan1 = false;
      this.$router.push('About');
    }
    else if (data == 2) {
      this.style2 = true;
      this.defaultspan2 = false;
    }
    else if (data == 3) {
      this.style3 = true;
      this.defaultspan3 = false;
    }
    else if (data == 4) {
      this.style4 = true;
      this.defaultspan4 = false;
    }
  },
  methods: {
    homepage() {
      this.$router.push({ name: 'Home' });
    },
    unbind1() {
      this.style1 = true;
      this.defaultspan1 = false;
      this.style2 = false;
      this.defaultspan2 = true;
      this.style3 = false;
      this.defaultspan3 = true;
      this.style4 = false;
      this.defaultspan4 = true;
      this.$router.push('About');
    },
    unbind2() {
      this.style1 = false;
      this.defaultspan1 = true;
      this.style2 = true;
      this.defaultspan2 = false;
      this.style3 = false;
      this.defaultspan3 = true;
      this.style4 = false;
      this.defaultspan4 = true;
      this.$router.push('Projects');
    },
    unbind3() {
      this.style1 = false;
      this.defaultspan1 = true;
      this.style2 = false;
      this.defaultspan2 = true;
      this.style3 = true;
      this.defaultspan3 = false;
      this.style4 = false;
      this.defaultspan4 = true;
      this.$router.push('Skills');
    },
    unbind4() {
      this.style1 = false;
      this.defaultspan1 = true;
      this.style2 = false;
      this.defaultspan2 = true;
      this.style3 = false;
      this.defaultspan3 = true;
      this.style4 = true;
      this.defaultspan4 = false;
      this.$router.push('Art');
    },
  } 
}
</script>

<style scoped>
body, html {
  height: 100%;
  width: 100%;
}
.menu {
  display: flex;
}
.offsetleft {
  float:left;
  height: 100%;
  width:100%;
}
.white-box {
  background: white;
  height: 100vh;
  width: 10%;
  left: 0;
  min-width: 190px;
  position: fixed;
}
.white-box-offset {
  background: white;
  height: 100vh;
  width: 10%;
  left: 0;
  min-width: 190px;
}
.bblock {
  background: rgb(119, 119, 119);
  top: 25%;
  height: 50vh;
  width: 4px;
  left: 190px;
  position: fixed;
  border-radius: 5px;
}
.fade-enter-active {
  transition: opacity 0.5s; 
}
@keyframes shiftleft-in {
  0% { width:100%;}
  100% { width:15%; }
}
.row {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
}
.rectbox {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 60px;
  font-size:  20px;
  color: white;
  background: rgb(180, 180, 180);
  border-radius: 2em;
  cursor: pointer;
}
.rectbox:hover {
  background: #8b8b8b;
}
.midbox {
  text-align:center;
  font-family: Roboto, Arial, sans-serif;
  font-size:32px;
  width:500px;
}
@import url('https://fonts.googleapis.com/css?family=Roboto:500');
span {
  color: rgb(187, 187, 187);
  font-family: Roboto, Arial, sans-serif;
  font-size: 30px;
  text-align: right;
  padding-right: 10%;
}
.spans1:hover {
  cursor: pointer;
  animation-name: movee1;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
.spans2:hover {
  cursor: pointer;
  animation-name: movee2;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
.spans3:hover {
  cursor: pointer;
  animation-name: movee3;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
.spans4:hover {
  cursor: pointer;
  animation-name: movee4;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
@keyframes movee1 {
  0% {
    padding-right: 0%;
    color: rgb(187, 187, 187);
  }
  100% {
    padding-right: 5%;
    color: #5c5d8d;
  }
}
@keyframes movee2 {
  0% {
    padding-right: 0%;
    color: rgb(187, 187, 187);
  }
  100% {
    padding-right: 5%;
    color: #7accc8;
  }
}
@keyframes movee3 {
  0% {
    padding-right: 0%;
    color: rgb(187, 187, 187);
  }
  100% {
    padding-right: 5%;
    color: #6f6dd3;
  }
}
@keyframes movee4 {
  0% {
    padding-right: 0%;
    color: rgb(187, 187, 187);
  }
  100% {
    padding-right: 5%;
    color: #49556b;
  }
}
svg {
  height: 65px;
  width: 85%;
  padding-left: 30%;
  min-width: 180px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}
.shiftleft-enter-active {
  animation: shiftleft-in 1s;
  animation-fill-mode: forwards;  
}
.style1 {
  cursor: default;
  color: #5c5d8d;
}
.style2 {
  cursor: default;
  color: #7accc8;
}
.style3 {
  cursor: default;
  color: #6f6dd3;
}
.style4 {
  cursor: default;
  color: #49556b;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}
.slide-enter-to {
  position: absolute;
  right: 0;
}
.slide-enter-from {
  position: absolute;
  right: -100%;
}
.slide-leave-to {
  position: absolute;
  left: -100%;
}
.slide-leave-from {
  position: absolute;
  left: 0;
}
.shadow {
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8);
}
@media (max-width: 900px) {

.white-box {
  background: white;
  height: 100vh;
  left: 0;
  min-width: 5vw;
  position: fixed;
}
.white-box-offset {
  background: white;
  height: 100vh;
  left: 0;
  min-width: 5vw;
}
span {
  color: rgb(187, 187, 187);
  font-family: Roboto, Arial, sans-serif;
  font-size: 2vh;
  padding-top: 15px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.midbox{
  margin-top: 15px;
  background: rgb(207, 207, 207);
  height: 18px;
  border-radius: 10px;
  width: 18px;
  float: right;
  display: none;
}
.bblock {
  display: none;
}
.rectbox {
  display: none;
}
.shadow {
  display: none;
}
.row {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5vw;
  flex-wrap: nowrap;
}

}
</style>