<template>
  <div class="home">
    <div class="workarea">
      <div class="hider">
        <div class="tword">
          <div>hello world</div>
          <div>artist</div>
          <div>programmer</div>
          <div>student</div>
          <div>hello world</div>
        </div>
      </div>
      <div title="[ I am Richie ]" class="title">[ I am Richie ]</div>
      <div class="menu">
        <div  @click="goAbout" class="word1">About</div>
        <div class="word">&nbsp;-&nbsp;</div>
        <div @click="goProj" class="word1">Projects</div>
        <div class="word">&nbsp;-&nbsp;</div>
        <div @click="goSkill" class="word1">Skills</div>
        <div class="word">&nbsp;-&nbsp;</div>
        <div @click="goArt" class="word1">Art</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Haome',
  methods: {
    goAbout() {
      let data = 1;
      this.$router.push({
        name: "About", 
        params: { data }
      });
    },
    goProj() {
      let data = 2;
      this.$router.push({
        name: "Projects", 
        params: { data }
      });
    },
    goSkill() {
      let data = 3;
      this.$router.push({
        name: "Skills", 
        params: { data }
      });
    },
    goArt() {
      let data = 4;
      this.$router.push({
        name: "Art", 
        params: { data }
      });
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: "Haettenschweiler";
  src: local("Haettenschweiler"),
   url(./fonts/Haettenschweiler-Regular.ttf) format("truetype");
}
body, html {
  height: 100%;
}
.home {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  background: rgb(75, 75, 75);
  color: rgb(196, 196, 196);
}
.workarea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: default;
}
.menu {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2.5vw;
  color: rgb(126, 126, 126);
}
.title {
  font-family: Haettenschweiler;
  font-size: 10vw;
  letter-spacing: 0.05em;
  margin-top: -5.8vw;
  text-shadow: 2px 2px 5px rgb(44, 44, 44);
  animation: glitch 1s linear infinite;
}
@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

.title:before,
.title:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.title:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

.title:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}
.word {
  font-family: Haettenschweiler;
  font-size: 2.7vw;
  letter-spacing: 0.05em;
}
.word1 {
  font-family: Haettenschweiler;
  font-size: 2.7vw;
  letter-spacing: 0.05em;
}
.word1:hover {
  cursor: pointer;
  animation-name: movee;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
}
@keyframes movee {
  0% {
    margin-bottom: 0%;
  }
  100% {
    margin-bottom: -1%;
    color: rgb(209, 209, 209);
  }
}
.hider {
  overflow: hidden;
  font-family: Haettenschweiler;
  font-size: 2vw;
  color: rgb(156, 156, 156);
  margin-left: -18.5vw;
  height: 1.1em;
}
.tword {
  white-space: nowrap;
  min-width: 10vw;
  animation: openclose 5s ease-in-out infinite;
}

@keyframes openclose {
  0% {
    color: rgb(192, 192, 192);
    margin-top: -0.5vw;
    width: 0;
  }
  15% {
    color: rgb(192, 192, 192);
    margin-top: -0.5vw;
  }
  17% {
    color: rgb(192, 192, 192);
  }
  20% {
    color: rgb(97, 155, 167);
    margin-top: -3.5vw;
  }
  35% {
    color: rgb(97, 155, 167);
    margin-top: -3.5vw;
  }
  37% {
    color: rgb(97, 155, 167);
  }
  40% {
    color: rgb(176, 190, 91);
    margin-top: -6.5vw;
  }
  55% {
    color: rgb(176, 190, 91);
    margin-top: -6.5vw;
  }
  57% {
    color: rgb(176, 190, 91);
  }
  60% {
    color: rgb(160, 91, 102);
    margin-top: -9.5vw;
  }
  75% {
    color: rgb(160, 91, 102);
    margin-top: -9.5vw;
  }
  77% {
    color: rgb(160, 91, 102);
  }
  80% {
    color: rgb(192, 192, 192);
    margin-top: -12.5vw;
  }
  95% {
    color: rgb(192, 192, 192);
    margin-top: -12.5vw;
  }
  100% {
    color: rgb(192, 192, 192);
    margin-top: -12.5vw;
    width: 0;
    text-indent: 0;
  }
}
@media (max-width: 900px) {

.title {
  overflow:hidden;
  font-size: 19vw;
  letter-spacing: 0.05em;
  margin-top: -12vw;
  text-shadow: 2px 2px 5px rgb(44, 44, 44);
  animation: glitch 1s linear infinite;
}
.hider {
  overflow: hidden;
  font-size: 5vw;
  margin-left: -37.5vw;
  height: 1.1em;
}
.word {
  font-size: 6vw;
  letter-spacing: 0.05em;
}
.word1 {
  font-size: 6vw;
  letter-spacing: 0.05em;
}
.tword {
  white-space: nowrap;
  min-width: 20vw;
  animation: openclose 5s ease-in-out infinite;
}
@keyframes openclose {
  0% {
    color: rgb(192, 192, 192);
    margin-top: -2vw;
    width: 0;
  }
  15% {
    color: rgb(192, 192, 192);
    margin-top: -2vw;
  }
  17% {
    color: rgb(192, 192, 192);
  }
  20% {
    color: rgb(97, 155, 167);
    margin-top: -10vw;
  }
  35% {
    color: rgb(97, 155, 167);
    margin-top: -10vw;
  }
  37% {
    color: rgb(97, 155, 167);
  }
  40% {
    color: rgb(176, 190, 91);
    margin-top: -17.5vw;
  }
  55% {
    color: rgb(176, 190, 91);
    margin-top: -17.5vw;
  }
  57% {
    color: rgb(176, 190, 91);
  }
  60% {
    color: rgb(160, 91, 102);
    margin-top: -25vw;
  }
  75% {
    color: rgb(160, 91, 102);
    margin-top: -25vw;
  }
  77% {
    color: rgb(160, 91, 102);
  }
  80% {
    color: rgb(192, 192, 192);
    margin-top: -32vw;
  }
  95% {
    color: rgb(192, 192, 192);
    margin-top: -32vw;
  }
  100% {
    color: rgb(192, 192, 192);
    margin-top: -32vw;
    width: 0;
    text-indent: 0;
  }
}
}
</style>
