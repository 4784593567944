<template>
  <div class="about">
    <div class="topwhite">
      <div class="ofbox">
       <div class="title">I design & build my digital dreams.</div>
      </div>
    </div>
    <div class="botm">
      <div class="backdrop">
        <div class="workarea">
          <div class="firstrow">
            <div class="abouttext">
              <span>About</span>
            </div>
            <div class="socialwrap">
              <div class="social">
                <div @click="link1" class="imgsub">
                  <b-img-lazy fluid :src="logolist.img1" ></b-img-lazy>
                </div>
                <div @click="link2" class="imgsub">
                  <b-img-lazy fluid :src="logolist.img2" ></b-img-lazy>
                </div>
                <div @click="link3" class="imgsub">
                  <b-img-lazy fluid :src="logolist.img3" ></b-img-lazy>
                </div>
              </div>
            </div>
          </div>
          <div class="secrow" data-aos="zoom-out"> 
            <div class="sectext">
              I am Richie Ang. I have strong passion about bringing new ideas to life and will not
hesitate to take up new challenges and opportunities.
            </div>
          </div>
          <div class="thirdrow">
            <div class="cert" data-aos="fade-right">
              <div class="smalltext">Bachelor's Degree</div>
              <div class="bigtext">Computer Science with Business Minor</div>
              <div class="smalltext">Nanyang Technological University</div>
            </div>
            <div class="cert" data-aos="fade-left">
              <div class="smalltext">Diploma</div>
              <div class="bigtext">Aerospace Engineering</div>
              <div class="smalltext">Temasek Polytechnic</div>
            </div>
          </div>
          <div class="fourthrow" >
            <p data-aos="zoom-out">
              I am a current Year 3 Computer Science w/ Minor in Business undergraduate of NTU. I am a 
              driven, opportunist and an independent person. I have wide experiences in data analytical
              work and have about 3 years of experience with it. I have a Distinction in DS102: Data 
              analytics with Python from Hackwagon Academy back in 2019 and have since accumulated 
              experiences with AI/ machine learning analytical work. I enjoy problem solving and taking 
              up new challenges, which led me to constantly take part in Hackathons resulting in finalist
              and top 5 positions thus far.
            </p>
            <div data-aos="zoom-out">
              <p class="desc">
                Personally I aspire to be a software engineer who brings fresh and innovate ideas to our
                world. This drives me to learn and take up new skills in the area of web development and app
                development. I made my first windows application in 2018 of a <em>font organiser</em> for my
                work in digital illustration at the time. Since then, I have worked on many other projects 
                with the most notable one being an <em>ecommerce inventory manager</em> which is a one stop central
                for managing inventory across multiple ecommerce platforms. Once I find myself invested in an
                challenge, I will always give it my fullest attention and commitment to ensure its completion.
              </p>
              <p class="desc">
                Feel free to contact me through any channels.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "./component_assets/github.png"
import img2 from "./component_assets/linkedin.png"
import img3 from "./component_assets/instagram.png"

export default {
  name: 'About',
  data() {
    return {
      logolist: { img1: img1, img2: img2, img3: img3 },
    }
  },
  methods: {
    link1() {
      window.open("https://github.com/richieang002");
    },
    link2() {
      window.open("https://linkedin.com/in/richie-ang");
    },
    link3() {
      window.open("https://www.instagram.com/richie.akw/");
    },
  },
  mounted () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
body, html {
  height: 100%;
}
.about {
  height:100%;
  left: 0;
  width:100%;
}
.ofbox {
  width: 53vw;
  height:50vh;
  overflow:hidden;
}
.title {
  font-family:Arial , Helvetica, sans-serif;
  font-size: 6vw;
  text-align: left;
  color: #5c5d8d;
  font-weight: bold;
  padding-top: 5vw;
  animation: showTopText 0.3s;
}
@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
.topwhite{
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.botm {
  display: flex;
  justify-content: center;
}
.backdrop {
  width: 80%;
  background:#5c5d8d;
  border-radius: 1vw;
}
.firstrow{
  height: 4vw;
  display: flex;
  justify-content: space-between;
}
.abouttext {
  color: white;
  font-size: 2vw;
  padding-left: 2%;
  font-family:Arial , Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 20%;
  border-radius: 0.5vw;

}
.imgsub {
  width: 100%;
  border-radius: 1vw;
  cursor: pointer;
}
.imgsub:hover {
  background:#6f3b7c;
}
.social {
  height: 100%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
}
.workarea{
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 25vh;
  height: 70%;
}
.secrow {
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectext {
  padding-left: 2%;
  padding-right: 2%;
  color: white;
  font-size: 1.4vw;
  text-align: left;
  line-height: 120%;
}
.thirdrow {
  height: 8vw;
  margin-top: 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cert {
  height: 100%;
  width: 49%;
  background: #4f507e;
  border-radius: 0.5vw;
  padding-top:2%;
}
.smalltext {
  padding-left: 4%;
  font-size: 1.1vw;
  color: white;
  text-align: left;
}
.bigtext {
  padding-left: 4%;
  font-size: 1.4vw;
  color: #9b9cd4;
  text-align: left;
}
p {
  font-family:Arial , Helvetica, sans-serif;
  color: white;
  text-align: left;
  font-size: 1.3vw;
  line-height: 120%;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 5%;
}
p .secrow{
  margin-top: 4%;
}
.desc em {
  color: #ffa57b;
  font-style: normal;
}
.fourthrow {
  padding-bottom: 4vw;
}

@media (max-width: 900px) {

.topwhite{
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ofbox {
  height:25vw;
}
.title {
  padding-top: 5vw;
}
.workarea{
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 8vh;
  height: 70%;
}
.abouttext {
  font-size: 4vw;
}
.sectext {
  font-size: 2vw;
  margin-top: 10%;
}
.smalltext {
  font-size: 1.7vw;
}
.bigtext {
  font-size: 1.9vw;
}
.cert {
  height: 170%;
  width: 49%;
  background: #4f507e;
  border-radius: 0.5vw;
  margin-top: 20%;
}
p {
  font-family:Arial , Helvetica, sans-serif;
  color: white;
  text-align: left;
  font-size: 1.9vw;
  line-height: 120%;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 18%;
}
.desc {
  margin-top: 7%;
}
.social {
  height: 100%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
}
}
</style>